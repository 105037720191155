<template>
  <v-layout v-if="_.size(detail) > 0" row wrap>
    <template v-if="detail.status.suscription">
      <v-flex xs12 lg12 md12>
        <v-card>
          <titleCard title="Precios de las suscripciones" subtitle="Acá puede modificar los precios">
            <template slot="button">
              <v-btn icon flat color="accent" @click="getSuscription(detail.id)">
                <v-icon>refresh</v-icon>
              </v-btn>
            </template>
          </titleCard>
          <v-divider></v-divider>
          <v-data-table
            :items="suscriptions"
            class="elevation-0"
            :headers="[
              { text: 'Nombre', align: 'left', sortable: false },
              { text: 'Precio Plan', align: 'right', sortable: false },
              { text: 'Precio Normal', align: 'right', sortable: false },
              { text: 'Porcentaje', align: 'right', sortable: false },
              { text: '', align: 'left', sortable: false }
            ]"
            hide-actions
          >
            <template v-slot:items="props">
              <td>
                {{ typeShipping(props.item.suscription.type) }} -
                {{ props.item.suscription.name }}
                ({{ props.item.suscription.orders }})
              </td>
              <td class="text-xs-right">
                {{ props.item.price | toPrice }}
              </td>
              <td class="text-xs-right">
                {{ (detail.price.normal * props.item.suscription.orders) | toPrice }}
              </td>
              <td class="text-xs-right">
                <v-chip
                  label
                  :color="
                    totalPriceSuscription(props.item.suscription.orders, props.item.price) < 0 ? 'error' : 'success'
                  "
                  text-color="white"
                  class="ma-0"
                  small
                >
                  {{ totalPriceSuscription(props.item.suscription.orders, props.item.price) }} %
                </v-chip>
              </td>
              <td class="text-xs-right">
                <v-btn
                  v-if="user.role.id !== 7"
                  class="mx-0"
                  color="info"
                  small
                  @click="openDialogSuscription(props.item)"
                >
                  Editar
                </v-btn>
                <v-btn
                  v-if="user.role.id !== 7"
                  class="ml-2"
                  color="warning"
                  small
                  @click="deleteSuscription(props.item.id)"
                >
                  Eliminar
                </v-btn>
              </td>
            </template>
          </v-data-table>
          <v-divider light></v-divider>
          <v-card-actions align-end class="text-xs-right" bottom>
            <v-spacer></v-spacer>
            <v-btn color="accent" flat @click="openDialogSuscription(null)"> Agregar </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-dialog v-model="dialogSuscription" persistent max-width="300px">
        <v-card>
          <v-card-title>
            <span class="headline"> Suscripcion </span>
          </v-card-title>
          <v-card-text>
            <v-layout row wrap>
              <v-flex xs12 sm12 md12>
                <v-select
                  v-model="suscription"
                  :items="suscriptionsList"
                  :item-text="
                    e => {
                      return `${typeShipping(e.type)} - ${e.name} `
                    }
                  "
                  item-value="id"
                  label="Plan"
                  outline
                  @change="updateSuscription"
                ></v-select>
                <template v-if="suscriptionActive">
                  <p>Pedidos: {{ suscriptionActive.orders }}</p>
                  <p>Tipo de entrega: {{ typeShipping(suscriptionActive.type) }}</p>
                </template>
              </v-flex>
              <v-flex xs12 sm12 md12>
                <v-text-field v-model="price" label="Precio" outline clearable></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="default" flat @click.native="dialogSuscription = false">Volver</v-btn>
            <v-btn color="primary" flat @click.native="addSuscription">Actualizar</v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <v-flex v-if="detail.status.suscription">
      <v-divider class="mb-4 mt-4"></v-divider>
    </v-flex>
    <template v-if="detail.status.special">
      <v-flex xs12 lg12 md12>
        <v-card>
          <titleCard
            title="Precio de la oferta especial"
            subtitle="Asigne un precio, comunas y fechas para cuendo estara disponible esta oferta"
          >
          </titleCard>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap text-xs-center>
              <v-flex xs4>
                <v-text-field
                  v-model="specialPrice"
                  label="Precio especial"
                  type="number"
                  prepend-icon="attach_money"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-select
                  v-model="selectedStates"
                  :items="states"
                  :item-text="
                    e => {
                      const price = $options.filters.toPrice(e.value)
                      return `${e.name} (${price})`
                    }
                  "
                  :item-value="
                    e => {
                      return e
                    }
                  "
                  label="Listado de comunas"
                  multiple
                  chips
                  attach
                  hide-details
                >
                  <template slot="selection" slot-scope="{ item, index }">
                    <v-chip small>
                      <span>{{ item.name }}</span>
                    </v-chip>
                  </template>
                </v-select>
              </v-flex>
              <v-flex xs4>
                <v-menu
                  ref="menuDate"
                  v-model="menuDate"
                  :close-on-content-click="false"
                  :nudge-right="40"
                  lazy
                  transition="scale-transition"
                  offset-y
                  full-width
                  min-width="290px"
                >
                  <template v-slot:activator="scope">
                    <v-combobox
                      v-model="dates"
                      multiple
                      chips
                      small-chips
                      label="Fechas de disponibilidad"
                      readonly
                      v-on="scope.on"
                    ></v-combobox>
                  </template>
                  <v-date-picker v-model="dates" multiple></v-date-picker>
                </v-menu>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider light></v-divider>
          <v-card-actions align-end class="text-xs-right" bottom>
            <v-btn
              color="accent"
              flat
              :href="`https://www.rosalinda.cl/regalos-a-domicilio-oferta/${detail.id}-${detail.seo.url}.html`"
              target="_blank"
            >
              Ver oferta
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="accent" flat @click="updatePriceSpecial()"> Actualizar </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
    </template>
    <v-flex v-if="detail.status.special">
      <v-divider class="mb-4 mt-4"></v-divider>
    </v-flex>
    <template>
      <v-flex xs12 lg12 md12>
        <v-card>
          <titleCard
            title="Precios"
            subtitle="Acá puede modificar los precios normales y de alta demanda, o aplicar descuentos"
          >
          </titleCard>
          <v-divider></v-divider>
          <v-card-text>
            <v-layout row wrap text-xs-center>
              <v-flex xs4>
                <v-text-field
                  v-model="detail.price.normal"
                  label="Normal"
                  type="number"
                  prepend-icon="attach_money"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="detail.price.high"
                  label="Alta demanda"
                  :disabled="!detail.price.status"
                  prepend-icon="attach_money"
                ></v-text-field>
              </v-flex>
              <v-flex xs4>
                <v-switch
                  v-model="detail.price.status"
                  label="Habilitar Alta demanda"
                  :value="1"
                  hide-details
                ></v-switch>
              </v-flex>
              <v-flex xs12>
                <v-divider></v-divider>
              </v-flex>
              <v-flex xs4>
                <v-switch
                  v-model="detail.sale.statusQuantity"
                  label="Oferta por cantidad"
                  :value="true"
                  hint="Texto"
                ></v-switch>
              </v-flex>
              <v-flex xs4>
                <v-switch
                  v-model="detail.sale.statusDate"
                  label="Oferta con fecha limite"
                  :value="true"
                  hint="Texto"
                ></v-switch>
              </v-flex>
              <v-flex xs4>
                <v-text-field
                  v-model="detail.sale.price"
                  label="Precio oferta"
                  type="number"
                  :disabled="!detail.sale.statusDate && !detail.sale.statusQuantity"
                  prepend-icon="attach_money"
                  :hint="
                    (!detail.price.status && detail.sale.price > detail.price.normal) ||
                    (detail.price.status && detail.sale.price > detail.price.high)
                      ? 'Precio oferta es mayor al precio de venta'
                      : ''
                  "
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="detail.sale.quantity"
                  label="Cantidad de articulos"
                  type="number"
                  :disabled="!detail.sale.statusQuantity"
                  placeholder="$"
                ></v-text-field>
              </v-flex>
              <v-flex xs6>
                <v-text-field
                  v-model="detail.sale.expiration"
                  type="date"
                  label="Fecha limite"
                  :disabled="!detail.sale.statusDate"
                ></v-text-field>
              </v-flex>
            </v-layout>
          </v-card-text>
          <v-divider light></v-divider>
          <v-card-actions align-end class="text-xs-right" bottom>
            <v-spacer></v-spacer>
            <v-btn color="accent" flat @click="save"> Listo </v-btn>
          </v-card-actions>
        </v-card>
      </v-flex>
      <v-flex xs12 lg12 md12>
        <v-card>
          <titleCard title="Historial de precios" subtitle="Precios normales">
            <template slot="button">
              <v-btn color="grey" dark flat icon @click="changeChart">
                <v-icon v-if="!chart1" size="20">far fa-chart-bar</v-icon>
                <v-icon v-else size="20">far fa-list-alt</v-icon>
              </v-btn>
            </template>
          </titleCard>
          <v-divider></v-divider>
          <template v-if="!chart1">
            <v-data-table
              :items="detail.price.history_price"
              class="elevation-1"
              :headers="[
                { text: 'Fecha', align: 'left', sor: false },
                { text: 'Precio Normal Antes', align: 'right', sortable: false },
                {
                  text: 'Precio Normal Despues',
                  align: 'right',
                  sortable: false
                },
                {
                  text: 'Precio Alta Demanda Antes',
                  align: 'right',
                  sortable: false
                },
                {
                  text: 'Precio Alta Demanda Despues',
                  align: 'right',
                  sortable: false
                },
                { text: 'Usuario', align: 'left', sortable: false }
              ]"
              hide-actions
            >
              <template v-slot:items="props">
                <td>{{ $moment(props.item.date).format('dddd DD-MM-YYYY') }}</td>
                <td class="text-xs-right">
                  {{ props.item.old_normal | toPrice }}
                </td>
                <td class="text-xs-right">{{ props.item.normal | toPrice }}</td>
                <td class="text-xs-right">{{ props.item.old_high | toPrice }}</td>
                <td class="text-xs-right">{{ props.item.high | toPrice }}</td>
                <td>{{ props.item.user }}</td>
              </template>
            </v-data-table>
          </template>
          <template v-else>
            <v-card-text>
              <LineChart :chart-data="datacollection" :options="optionsData"></LineChart>
            </v-card-text>
          </template>
        </v-card>
      </v-flex>
      <v-flex xs12 lg12 md12>
        <v-card>
          <titleCard title="Historial de precios" subtitle="Solo ofertas"></titleCard>
          <v-divider></v-divider>
          <v-data-table
            :items="detail.price.history_price_sale"
            class="elevation-1"
            :headers="[
              { text: 'Fecha', align: 'left', sortable: false },
              { text: 'Antes', align: 'right', sortable: false },
              { text: 'Despues', align: 'right', sortable: false },
              { text: 'Cantidad', align: 'right', sortable: false },
              { text: 'Expira', align: 'left', sortable: false },
              { text: 'Usuario', align: 'left', sortable: false }
            ]"
            hide-actions
          >
            <template v-slot:items="props">
              <td>
                {{ $moment(props.item.date_add).format('dddd DD-MM-YYYY') }}
              </td>
              <td class="text-xs-right">{{ props.item.price_old | toPrice }}</td>
              <td class="text-xs-right">{{ props.item.price | toPrice }}</td>
              <td class="text-xs-right">{{ props.item.quantity }}</td>
              <td class="text-xs-left">
                <span v-if="props.item.expire !== '0000-00-00 00:00:00'">
                  {{ $moment(props.item.expire).format('dddd DD-MM-YYYY') }}
                </span>
                <span v-else>
                  -
                </span>
              </td>
              <td>{{ props.item.user }}</td>
            </template>
          </v-data-table>
        </v-card>
      </v-flex>
    </template>
  </v-layout>
</template>

<script>
import { GET_PRODUCT, GET_SUSCRIPTIONS } from '../../config'
import titleCard from '../useful/titleCard.vue'
import LineChart from '../useful/lineChart'

export default {
  name: 'ProductPrice',
  components: { titleCard, LineChart },
  props: ['product', 'getProduct'],
  data: () => ({
    loadingBtn: false,
    loading: false,
    active: true,
    stock: true,
    scheduleRetirement: 0,
    detail: {},
    chart1: false,
    datacollection: {},
    optionsData: {
      hover: {
        // Overrides the global setting
        mode: 'index'
      },
      line: {
        backgroundColor: 'transparent'
      },
      legend: {
        labels: {
          fontColor: 'grey',
          fontSize: 12
        }
      },
      scales: {
        yAxes: [
          {
            ticks: {
              fontColor: 'grey',
              fontSize: 8,
              suggestedMin: 0,
              min: 0,
              max: 100000
            }
          }
        ],
        xAxes: [
          {
            ticks: {
              fontColor: 'grey',
              fontSize: 12,
              stepSize: 1,
              beginAtZero: true
            }
          }
        ]
      },
      reponsive: true,
      maintainAspectRatio: false
    },
    suscriptions: [],
    suscriptionsList: [],
    dialogSuscription: false,
    suscription: null,
    suscriptionActive: null,
    price: null,
    idEdit: null,
    selectedStates: [],
    menuDate: false,
    dates: [],
    selectedDates: [],
    specialPrice: null
  }),
  computed: {
    user() {
      return this.$store.getters.getUser
    },
    states() {
      const user = this.$store.getters.getUser
      const communes = this._.filter(this.$store.getters.getCommunes, ['shopId', user.shopID])
      return this._.orderBy(communes, ['value'], ['asc'])
    }
  },
  watch: {
    product() {
      this.detail = this.product
      if (this.detail.status.suscription) {
        this.getSuscription(this.detail.id)
      }
      if (this.detail.status.special) {
        this.specialPrice = this.detail.special.price
        this.selectedStates = this.detail.special.zones
        this.dates = this.detail.special.dates
      }
    }
  },
  mounted() {
    this.detail = this.product
    if (this.detail.status.suscription) {
      this.getSuscription(this.detail.id)
    }
    if (this.detail.status.special) {
      this.specialPrice = this.detail.special.price
      this.selectedStates = this.detail.special.zones
      this.dates = this.detail.special.dates
    }
  },
  methods: {
    async updatePriceSpecial() {
      const { detail, selectedStates, dates, specialPrice } = this
      try {
        await this.$http.put(`${GET_PRODUCT}/${detail.id}/special`, {
          states: selectedStates,
          dates,
          price: specialPrice
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Precios actualizados'
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async getSuscriptionList() {
      const res = await this.$http.get(GET_SUSCRIPTIONS)
      this.suscriptionsList = res.data
    },
    async getSuscription(id) {
      const res = await this.$http.get(`${GET_PRODUCT}/${id}/suscription`)
      this.suscriptions = res.data
      this.getSuscriptionList()
    },
    async save() {
      const { detail } = this
      try {
        await this.$http.put(`${GET_PRODUCT}/${detail.id}/price`, {
          price: detail.price,
          sale: detail.sale,
          id: detail.id
        })
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Precios actualizados'
        })
        this.getProduct()
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async addSuscription() {
      try {
        const params = {
          price: this.price,
          suscription: this.suscription
        }
        await this.$http.post(`${GET_PRODUCT}/${this.detail.id}/suscription`, params)
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Actualizado'
        })
        this.dialogSuscription = false
        this.getSuscription(this.detail.id)
      } catch (error) {
        this.$store.dispatch('changeSnack', {
          active: true,
          text: 'Ocurrio un error, intente nuevamente'
        })
      }
    },
    async deleteSuscription(id) {
      await this.$http.delete(`${GET_PRODUCT}/${this.detail.id}/suscription/${id}`)
      this.getSuscription(this.detail.id)
    },
    totalPriceSuscription(orders, price) {
      const total = this.detail.price.normal * orders
      const p = (price * 100) / total - 100
      return p.toFixed(1)
    },
    typeShipping(type) {
      if (type === 1) {
        return 'Semanal'
      }
      if (type === 2) {
        return 'Quincenal'
      }
      if (type === 3) {
        return 'Mensual'
      }
    },
    updateSuscription(e) {
      const index = this._.findIndex(this.suscriptionsList, ['id', e])
      this.suscriptionActive = this.suscriptionsList[index]
    },
    openDialogSuscription(i) {
      if (i) {
        this.updateSuscription(i.suscription.id)
        this.idEdit = i.id
        this.price = i.price
        this.suscription = i.suscription.id
      }
      if (!i) {
        this.idEdit = null
        this.price = null
        this.suscription = null
      }
      this.dialogSuscription = true
    },
    changeChart() {
      this.chart1 = !this.chart1
      this.setFillDataDesigner(this.detail.price.history_price)
    },
    setFillDataDesigner(data) {
      const labels = []
      const normalB = []
      const normalA = []
      const highB = []
      const highA = []

      data.forEach(doc => {
        labels.push(doc.date)
        normalB.push(doc.old_normal)
        normalA.push(doc.normal)
        highB.push(doc.old_high)
        highA.push(doc.high)
      })

      this.datacollection = {
        labels,
        datasets: [
          {
            label: 'Normal antes',
            borderColor: '#2196f3',
            backgroundColor: '#2196f3',
            showLine: true,
            spanGaps: false,
            data: normalB,
            lineTension: 0,
            scaleStepWidth: 1
          },
          {
            label: 'Normal despues',
            borderColor: '#4caf50',
            backgroundColor: '#4caf50',
            showLine: true,
            spanGaps: false,
            data: normalA,
            lineTension: 0,
            scaleStepWidth: 1
          },
          {
            label: 'Alta demanda antes',
            borderColor: '#ffc107',
            backgroundColor: '#ffc107',
            showLine: true,
            spanGaps: false,
            data: highB,
            lineTension: 0,
            scaleStepWidth: 1
          },
          {
            label: 'Alta demanda despues',
            borderColor: '#ff1744',
            backgroundColor: '#ff1744',
            showLine: true,
            spanGaps: false,
            data: highA,
            lineTension: 0,
            scaleStepWidth: 1
          }
        ]
      }

      console.log(this.datacollection)
    }
  }
}
</script>
