import { render, staticRenderFns } from "./price.vue?vue&type=template&id=6419e1a0&"
import script from "./price.vue?vue&type=script&lang=js&"
export * from "./price.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!C:/proyectos/rosalinda-store/node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VBtn } from 'vuetify/lib'
import { VCard } from 'vuetify/lib'
import { VCardActions } from 'vuetify/lib'
import { VCardText } from 'vuetify/lib'
import { VCardTitle } from 'vuetify/lib'
import { VChip } from 'vuetify/lib'
import { VCombobox } from 'vuetify/lib'
import { VDataTable } from 'vuetify/lib'
import { VDatePicker } from 'vuetify/lib'
import { VDialog } from 'vuetify/lib'
import { VDivider } from 'vuetify/lib'
import { VFlex } from 'vuetify/lib'
import { VIcon } from 'vuetify/lib'
import { VLayout } from 'vuetify/lib'
import { VMenu } from 'vuetify/lib'
import { VSelect } from 'vuetify/lib'
import { VSpacer } from 'vuetify/lib'
import { VSwitch } from 'vuetify/lib'
import { VTextField } from 'vuetify/lib'
installComponents(component, {VBtn,VCard,VCardActions,VCardText,VCardTitle,VChip,VCombobox,VDataTable,VDatePicker,VDialog,VDivider,VFlex,VIcon,VLayout,VMenu,VSelect,VSpacer,VSwitch,VTextField})
